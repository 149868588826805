/** @module lib/plan_data/itemMunger
 *
 * @desc
 * This is a domain class, responsible for munging the set of kits,
 * from the point of view of a specific item
 * It's very janktastic at the moment... let's see how long this comment lasts
 */
import {List, Map, OrderedMap, OrderedSet} from 'immutable'

import {PRIMITIVES} from './data'
import {allByElementId} from '../responsesHelper'

/** return the item based on the item slug */
export const itemBySlug = ({itemSlug, items}) => items.find(item => item.get('slug') === itemSlug) || Map()

// Returns the item based on the item header
export const itemByHeader = ({items, header}) => items.find(itemAttributes => itemAttributes.get('header') === header)

// Returns the top kit of an item based on the item header
export const topKitByItemHeader = ({header, items, kits}) => kits.get(itemByHeader({items, header}).get('kit-id'))

/** Build a dictionary of kits indexed by ID. Used internally for speed.*/
export const kitDictionary = kits => (kits ? kits.reduce((dictionary, kit) => dictionary.set(kit.get('id'), kit), Map()) : List())

/** Build a dictionary of listMappings indexed by ID
 * @param {List} listMappings dictionary
*/
export const listMappingDictionary = listMappings => listMappings.reduce((dictionary, listMapping) => dictionary.set(listMapping.get('id'), listMapping), Map())

/** Build a dictionary of elements indexed by ID
 * @param {List} kits
*/
export const elementDictionary = kits => (
  kits
    .map(kit => kit.get('elements'))
    .flatten(true)
    .reduce((dictionary, element) => dictionary.set(element.get('id'), element), Map())
)


/** find a specific kit by id (kits in this case is the kitDictionary) */
export const kitById = (kitId, kits) => kits.get(kitId)

export const isPrimitive = element => {
  const types = PRIMITIVES.map(prim => prim.key)
  return types.includes(element.get('type'))
}

/* take an element and kits, assuming it points to another kit, based on the value
 * of the type. And grab the elements of the kit that it points to.
 */
export const dereferenceElements = (element, kits) => {
  if (isPrimitive(element))
    return List.of(element)

  return List(kitById(element.get('type'), kits).get('elements'))
}

/** Return a flat list of all elements, starting from the top level kit,
 * regardless of the fact that they might be nested within another kit.
 * Basically pretend it's one giant list of elements, with no nesting
 *
 * @param {Map} kits dictionary
 * @param {Object} item
 *
 * @returns {List} of elements of all the primitive elements of a given item's top-kit.
*/
export const allPrimitiveElements = (item, kits) => {
  const reducer = (collection, element) => {
    if (isPrimitive(element))
      return collection.push(element)
    else
      return dereferenceElements(element, kits).reduce(reducer, collection)
  }

  return kitById(item.get('kit-id'), kits).get('elements').reduce(reducer, List())
}


/** Return a flat list of all item element ids, starting from the top level kit,
 * regardless of the fact that they might be nested within another kit.
 * Basically pretend it's one giant list of elements, with no nesting
 *
 * It returns a List of ids,
 * ['b53734-d827-4339-8869-0f82a8e66304', '524d73-43d8-4bd2-8424-c1d22a68cb52']
 * @param {Map} kits dictionary
 * @param {Object} item
 *
 * @returns {List} that is the id of all the primitive elements of a
 * given item's top-kit.
*/
export const allPrimitives = (item, kits) => (
  allPrimitiveElements(item, kits).map(element => element.get('id'))
)

/**
 * Returns a list of the elements of the conditional kit that is associated to the value of an element that has a list-mapping
 *
 * @param {Map} kits
 * @param {Map} listMappings
 * @param {Map} element
 *
 * @returns {List} of conditional elements from a list-mapping based on the value of the element
*/
export const conditionalKitElementsForElementValue = ({element, listMappings, kits}) => {
  if (element.get('list-mapping')) {
    const mapping = listMappings
      .getIn([element.get('list-mapping'), 'mappings'])
      .find(listMapping => element.get('value') === listMapping.get('value'))

    return mapping && kits.getIn([mapping.get('conditional-kit-id'), 'elements'], '')
  }
}

/**
 * @param {Map} listMappings
 * @param {Map} element
 *
 * @returns {Boolean} whether an element has a list-mapping and any of the mappings have a conditional kit associated
*/
export const isElementWithConditionalKits = ({element, listMappings}) => (
  listMappings
    .getIn([element.get('list-mapping'), 'mappings'], Map())
    .some(mapping => mapping.get('conditional-kit-id'))
)

/**
 * Finds all the conditional kits for an element with a list-mappings and returns a flat list of every element inside those kits
 *
 * @param {Map} kits
 * @param {Map} listMappings
 * @param {Map} element
 *
 * @returns {List} of all elements that belong to every conditional kit associated with an element's list-mapping
*/
export const allConditionalKitElementsForElement = ({element, listMappings, kits}) => {
  if (element.get('list-mapping')) {
    return listMappings
      .getIn([element.get('list-mapping'), 'mappings'])
      .filter(mapping => mapping.get('conditional-kit-id'))
      .map(mapping => kits.getIn([mapping.get('conditional-kit-id'), 'elements']))
      .flatten(true)
  } else {
    return List()
  }
}

/**
 * @param {Map} kits
 * @param {Map} element
 *
 * @returns {Map} of the kit that an element belongs too
*/
export const elementKit = ({element, kits}) => (
  kits.find(kit => kit
    .get('elements')
    .find(kitElement => kitElement.equals(element))
  )
)

/**
 *  Finds the kit an element belongs too and checks if it belongs to a list-mapping, if it does then sets a new key of list-mapping-value
 *  with the value as the value on the element, otherwise returns the element unchanged.
 *
 * @param {Map} kits
 * @param {Map} element
 *
 * @returns {Map} of the element with the list-mapping value if it is part of a conditional kit
*/
export const elementListMappingValue = ({element, kits, listMappings}) => {
  const elementKitId = elementKit({element, kits}).get('id')

  const mappingData = listMappings
    .toList()
    .map(listMapping => listMapping.get('mappings'))
    .flatten(true)
    .find(mapping => mapping.get('conditional-kit-id', '') === elementKitId)

  if (mappingData)
    return element.set('list-mapping-value', mappingData.get('value'))
  else
    return element
}

/** Flattens all elements of a kit.
 * @param {Map} kit Specific kit whose elements are to be flattened
 * @param {Map} kits It is a kitDictionary in this case
 * @returns {OrderedMap} A map of all flattened elements for a kit
 */
export const flattenedKitElements = ({elements, kits}) => {
  const reducer = (collection, element) => {
    if (isPrimitive(element)) {
      return collection.set(element.get('id'), Map({element, group: false}))
      // TODO: remove 'addMore' when everything is using Transformer
    } else if (element.get('add-more') || element.get('addMore')) {
      return (
        collection.set(
          element.get('id'),
          Map({
            element: dereferenceElements(element, kits)
              .reduce(reducer, OrderedMap()),
            group: true
          })
        )
      )
    } else {
      return dereferenceElements(element, kits).reduce(reducer, collection)
    }
  }

  return elements.reduce(reducer, OrderedMap())
}

/** Builds out a map of element ids and the conditional mapping.
 * @param {Map} elements Elements of a kit that are to be flattened
 * @param {Map} kits It is a kitDictionary in this case
 * @param {Map} listMappings
 * @returns {Map} A map of all flattened elements for a kit
 */
export const listMappingsByTopKitElements = ({elements, kits, listMappings}) => {
  const reducer = (values, element) => {
    const childElement = element.get('element')

    if (element.get('group')) {
      return childElement.reduce(reducer, values)
    } else {
      const validListMappings = listMappings.get(childElement.get('list-mapping'))

      return validListMappings ? values.set(childElement.get('id'), validListMappings) : values
    }
  }

  return flattenedKitElements({elements, kits}).reduce(reducer, Map())
}

/**
 * Adds conditional elements to the passed in elements.
 *
 * @param {Map} kits
 * @param {Map} listMappings
 * @param {List} elements
 *
 * @returns {List} of elements passed in with their corresponding conditional elements.
 */
export const addValidListingMappingsToElements = ({kits, listMappings, elements}) => {
  const validListMappings = listMappingsByTopKitElements({elements, kits, listMappings})
  let updatedElements = elements

  if (validListMappings) {
    validListMappings.map(listMapping => {
      listMapping.get('mappings').map(mapping => {
        const currentKitId = mapping.get('conditional-kit-id')
        if (currentKitId)
          updatedElements = updatedElements.concat(kitById(currentKitId, kits).get('elements'))
      })
    })
  }

  return updatedElements
}

/** Return a flat list of all elements, starting from the top level kit
 * including nested elements and conditional kit elements which are gotten
 * through the listmappings.
 *
 * It returns a List of ids,
 * ['b53734-d827-4339-8869-0f82a8e66304', '524d73-43d8-4bd2-8424-c1d22a68cb52']
 * @param {Map} kits dictionary
 * @param {String} kitId whose primitive elements are to be returned.
 *
 * @returns {List} that is the id of all the primitive elements of a given item's
 *  top-kit including conditionals.
*/
export const allPrimitiveWithConditionalElementIds = ({kitId, kits, listMappings, contactsOnly = false}) => {
  let elements = kitById(kitId, kits).get('elements')
  elements = addValidListingMappingsToElements({kits, listMappings, elements})

  const reducer = (collection, element) => {
    if (isPrimitive(element)) {
      if (contactsOnly)
        return element.get('type') === 'CONTACT' ? collection.push(element.get('id')) : collection
      else
        return collection.push(element.get('id'))
    } else {
      return dereferenceElements(element, kits).reduce(reducer, collection)
    }
  }

  return elements.reduce(reducer, List())
}

/** find all elements of an item not including conditionals or elements that are a kit */
export const itemElements = (item, kits) => kitById(item.get('kit-id'), kits).get('elements').map(element => dereferenceElements(element, kits))

/**
 * Finds the first element of an item's top kit and returns its id.
 *
 * @param {Map} kits dictionary
 * @param {object} item
 *
 * @returns {string} that is the id of the first element in the kit of a given item's top-kit
 */
export const firstElementIdForItem = ({item, kits}) => (
  kits.get(item.get('kit-id')).get('elements').first().get('id')
)


/**
 * @param {Map} topKit
 * @returns {Boolean}
 */
export const isCompoundItem = topKit => topKit.get('elements').size > 1

/**
 * @param {List} allElements
 * @param {Map} kitMap
 * @returns {Integer}
 */
export const indexOfConditional = ({allElements, kitMap}) => (

  allElements.findIndex(oldElement =>
    oldElement.get('list-mapping') === kitMap.get('listMapping').get('id')
  ) + 1
)

/**
 * @param {List} allElements
 * @returns {List}
 */
export const flattenedConditionalElements = allElements => {
  let flattenedConditionalElementsList = List()

  allElements.forEach(elements => {
    if (Map.isMap(elements))
      flattenedConditionalElementsList = flattenedConditionalElementsList.push(elements)
    else
      elements.map(element => { flattenedConditionalElementsList = flattenedConditionalElementsList.push(element) })
  })

  return flattenedConditionalElementsList
}

/**
 * @param {Map} kitMap Conditional Map whose elements are to be inserted
 * @param {List} elements
 * @returns {List}
 */
export const elementsWithInsertedConditionals = ({kitMap, elements}) => {
  const elementIndexValue = indexOfConditional({allElements: elements, kitMap})
  // if for some reason, there is no conditional there, then insert the new fields at the bottom.
  const index = elementIndexValue > 0 ? elementIndexValue : elements.size + 1
  const allElements = elements.insert(index, kitMap.getIn(['kit', 'elements']))
  // we need to inject the extra elements in a specific order, that's why we're using insert.
  return OrderedSet(flattenedConditionalElements(allElements)).toList()
}

/** This adds elements of conditional kits whose parent is an element with
 * listMappings
 * @param {List} responses
 * @param {Map} kit
 * @param {List} kits
 * @param {Map} listMappings
 * @returns {List}
 */
export const rectifyConditionalMaps = ({responses, parentElement, childElements, kits, listMappings}) => {
  // go over conditional fields and check the value.
  // If it corresponds to a mapping, grab additional elements from the mapped kit
  const reducer = (values, listMapping, key) => {
    const data = allByElementId(responses, key)

    if (!data) {
      return values
    } else {
      let newValues = values

      data.map(datum => {
        const moreKitId = listMapping.get('mappings').find(map => datum.get('value') === map.get('value'))

        if (moreKitId && moreKitId.get('conditional-kit-id')) {
          newValues = newValues.push(Map({
            kit: kitById(moreKitId.get('conditional-kit-id'), kits),
            listMapping
          }))
        }
      })

      return newValues
    }
  }
  let rectifiedElements = childElements
  const elements = parentElement.isEmpty() ? childElements : List.of(parentElement)

  listMappingsByTopKitElements({elements, kits, listMappings})
    .reduce(reducer, List())
    .forEach(conditionalMap => {
      if (conditionalMap && conditionalMap.has('kit'))
        rectifiedElements = elementsWithInsertedConditionals({kitMap: conditionalMap, elements: rectifiedElements})
    })

  return rectifiedElements
}


/**
 * @param {list} kits
 * @param {Map} itemTopKit
 *
 * @returns {string} list-mapping id of the item's top kit if it is a list mapping
 */
export const itemTopKitListMappingId = ({kits, itemTopKit}) => (
  kits
    .get(itemTopKit.getIn(['elements', 0, 'type']))
    .getIn(['elements', 0, 'list-mapping'])
)

/**
 * Builds a dictionary of all prmitive elementIds as key and the item they belong to as value.
 *
 * @param {List} items
 * @param {Map} kits
 * @param {Map} listMappings
 *
 * @returns {Map} of elementId to their corresponding item.
 */
export const elementItemDictionary = ({items, kits, listMappings}) => {
  const reducer = (collection, item) => {
    let newCollection = collection
    allPrimitiveWithConditionalElementIds({kitId: item.get('kit-id'), kits, listMappings})
      .map(elementId => {
        newCollection = newCollection.set(elementId, item)
      })
    return newCollection
  }

  return items.reduce(reducer, Map())
}

/**
 * Returns a flat list of all topKit elements in supplied items list
 * @param {List} items
 * @param {List} kits
 */
export const allTopKitElements = ({items, kits}) => items.reduce(
  (collection, item) => collection.concat(kitById(item.get('kit-id'), kits).get('elements').filterNot(element => isPrimitive(element))),
  List()
)

/**
 * Return a map of primitive elementId to topKit element
 * @param {List} items
 * @param {List} kits
 * @param {List} listMappings
 */
export const primitivesTopKitElementsDictionary = ({items, kits, listMappings}) => {
  const reducer = (collection, element) => {
    let newCollection = collection

    allPrimitiveWithConditionalElementIds({kitId: element.get('type'), kits, listMappings}).map(elementId => {
      newCollection = newCollection.set(elementId, element)
    })

    return newCollection
  }

  return allTopKitElements({items, kits}).reduce(reducer, Map())
}

/**
 * Recursively grabs the name of all elements of a kit including nested kits.
 *
 * @param {string} kitId
 * @param {Map} kits
 * @param {Map} listMappings
 *
 * @returns {List} of the name of all elements that can be found in a kit.
 */
export const allElementNamesAndListMappingValues = ({kitId, kits, listMappings}) => {
  let elements = kitById(kitId, kits).get('elements')
  elements = addValidListingMappingsToElements({kits, listMappings, elements})

  const reducer = (collection, element) => {
    const listMappingValues = listMappings
      .getIn([element.get('list-mapping'), 'mappings'], List())
      .map(mapping => mapping.get('value'))
    const newCollection = collection
      .concat(listMappingValues)
      .push(element.get('name'))

    if (isPrimitive(element))
      return newCollection
    else
      return dereferenceElements(element, kits).reduce(reducer, newCollection)
  }

  return elements.reduce(reducer, List())
}

/**
 * Adds a new attribute 'elementNames' to all items passed to it.
 *
 * @param {List} items
 * @param {Map} kits
 * @param {Map} listMappings
 *
 * @returns {List} of items with an elementNames attribute which is a List of the name of elements
 *   that belongs to the item.
 */
export const itemsWithElementAndCategoryNames = ({items, kits, listMappings, categoryDictionary}) => {
  const reducer = (collection, item) => {
    const elementNamesAndListMappingValues = allElementNamesAndListMappingValues({kitId: item.get('kit-id'), kits, listMappings})

    return collection.push(
      item
        .set('elementNamesAndListMappingValues', elementNamesAndListMappingValues)
        .set('categoryName', categoryDictionary.getIn([item.get('category-id'), 'name']))
    )
  }

  return items.reduce(reducer, List())
}
